import React from 'react';
import './Copyright.css';

const Copyright = () => {

    const date = new Date();
    const year = date.getFullYear();
    const text =  " "+year+ " Tom Cole Music. All rights reserved"
    return(
        <div className="Copyright">
            &copy;{text}
        </div>
    )

}

export default Copyright;