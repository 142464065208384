import React from 'react';
import './Artwork.css';

const Artwork = (props) => {
    return(
        <div className="Artwork">
            <img src={props.track.cover} alt={props.track.album} />
        </div>
    )
}

export default Artwork;