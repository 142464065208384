import React from 'react';
import EventSection from '../../Components/Events/Events';
import './Events.css';

const Events = (props) => {
	return (

		<div className="Events Page" id="events">
			<EventSection events={props.events}/>
		</div>

	)
}

export default Events;