import React from 'react';
import MaterialIcon from 'material-icons-react';
import './Arrow.css';

class Arrow extends React.Component {

    render() {
        const width = document.body.offsetWidth;
        let iconSize = 40;
        if (width <= 720) {
            iconSize = 32
        }
        if (width <= 480) {
            iconSize = 28
        }
        const extraClass = this.props.showing ? "PlayerArrow active" : "PlayerArrow";
        const arrow = this.props.showing ? "arrow_drop_down" : "bar_chart";
        return (
            <div className={extraClass}>
                <MaterialIcon icon={arrow} size={iconSize} color={"#fff"} onClick={this.props.clickHandler} />
            </div>
        )
    }

}

export default Arrow;