import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter, BrowserRouter as Router } from 'react-router-dom';
import Home from './Containers/Home/Home';
import NotFound from './Containers/404/404';
import Admin from './Containers/Admin/Admin';
import TomHenry from './Containers/TomHenry/TomHenry';
import './App.css';


class App extends Component {


  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/admin" component={Admin} />
          <Route path="/tomcoleandhenrybristow" component={TomHenry} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(App);
