import React from 'react';
import './AboutIntro.css';

const AboutIntro = (props) => {
    const text = props.text.split('\n').map((item, key) => {
        return (
            <p key={key}>{item}</p>
        )
    });
    
    
    //props.text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    return (
        <div className="curveArea">
            <div className="mainBox">
                <div className="curveSection">
                    <div className="Container">
                        <div className="IntroText">
                            <h3>ABOUT</h3>
                            <span>{text}</span>

                            <div className="Signature">
                                Tom Cole
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // <div className="AboutIntro">
        //     <div className="Container">
        //         <div className="IntroText">
        //             <p>{props.text}</p>
        //         </div>
        //     </div>
        // </div> 
    )
}

export default AboutIntro;