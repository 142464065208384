import React from 'react';
import Waveform from 'waveform-react';
import './Waveform.css';

class WaveformDisplay extends React.Component {

    state = {
        buffer: null,
        context: null,
        position: 0,
    }

    componentWillMount() {
        const context = this.getContext();
        this.setState(
            { context },
            this.getFile
        );
    }

    getContext = () => {
        window.AudioContext =
            window.AudioContext ||
            window.webkitAudioContext ||
            window.mozAudioContext ||
            window.oAudioContext;
        const context = new AudioContext();
        return context;
    };

    getAudioBuffer = async (path, context) => {
        const response = await fetch(path);
        const audioData = await response.arrayBuffer();
        return new Promise((resolve, reject) => {
            context.decodeAudioData(audioData, buffer => {
                return resolve(buffer);
            });
        });
    };

    getFile = async () => {
        const buffer = await this.getAudioBuffer(this.props.track.path, this.state.context);
        this.setState({ buffer });
    }

    render() {
        return (
            <div className="Waveform">
                <Waveform
                    // Audio buffer
                    buffer={this.state.buffer}
                    // waveform height
                    height={82}
                    markerStyle={{
                        // Position marker color
                        color: '#ff4617',
                        // Position marker width (in pixels)
                        width: 4
                    }}
                    // Optionally handle user manually changing position (0 - 1)
                    onPositionChange={pos => this.setState({ position: pos })}
                    // Wave plot type (line or bar)
                    plot="bar"
                    // Marker position on waveform (0 - 1)
                    position={this.state.position}
                    // redraw waveform on window size change (default: true)
                    responsive={false}
                    // Show position marker
                    showPosition={true}
                    waveStyle={{
                        // animate waveform on draw (default: true)
                        animate: true,
                        // waveform color
                        color: '#fff',
                        // width of each rendered point (min: 1, max: 10)
                        pointWidth: 1
                    }}
                    // waveform width
                    width={1300}
                />
            </div>
        )
    }
}

export default WaveformDisplay;