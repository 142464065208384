import React from "react";
import MaterialIcon from 'material-icons-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBandcamp } from '@fortawesome/free-brands-svg-icons';

const Track = props => {
    const playing = props.playing && props.trackPlaying.id === props.track.id;
    return (
        <li onClick={playing ? () => props.pauseHandler() : () => props.playHandler(props.track)}>
            <MaterialIcon icon={playing ? "pause" : "play_arrow"} size={28} color={"#fff"} />
            <span className="TrackNo">{props.track.id}</span>{props.track.title}
        </li>
    )
}

export default Track;