import React from 'react';
import Fade from 'react-reveal/Fade';
import Button from '../../Button/Button';
import './TourEvent.css';

const TourEvent = (props) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const date = new Date(props.event.date * 1000);
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const address = (<p>{props.event.venue + " - "}<span className="Time">{props.event.time}</span></p>)
    const moreInfo = props.event.info_link ? <Button style={{ textAlign: 'center' }} action={() => window.open(props.event.info_link, "_blank")}>MORE INFO</Button> : null;
    return (

        <div className="TourEvent">

                <div className="TourDate">
                    <div className="Month">
                        {month}
                    </div>
                    <div className="Day">
                        {day}
                    </div>
                </div>
                <div className="TourInfo">
                    <div className="Venue">
                        {props.event.name}
                    </div>
                    <div className="Address">
                        {address}
                    </div>
                    <div className="MoreInfo">
                        {moreInfo}
                    </div>
                </div>

        </div>

    )

}

export default TourEvent;