import React from 'react';
import './FindBandCamp.css';

const FindBandCamp = () => {
    return(
        <div className="Find">
            <h5>Available now on <a target="_blank" href="https://tomcole.bandcamp.com/">bandcamp</a></h5>
        </div>
    )
}

export default FindBandCamp;