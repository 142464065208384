import React from 'react';
import '../About.css';

const AboutText = (props) => {
    const textClass = props.reverse ? "About Section Reverse" : "About Section";

    return (
        <div className={textClass}>
            <div className="Text">
                <p>{props.text}</p>
                <p className="Endorsement">{props.endorsement}</p>
            </div>
        </div>
    )
}

export default AboutText;