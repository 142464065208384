import React from 'react';
import MaterialIcon from 'material-icons-react';
import Play from './Play/Play';
import Pause from './Pause/Pause';
import './Controls.css';

class Controls extends React.Component {

    render() {
        const width = document.body.offsetWidth;
        let iconSize = 56;
        if (width <= 720) {
            iconSize = 42
        }
        if (width <= 480) {
            iconSize = 32
        }
        const playIcon = this.props.playing ? <Pause iconSize={iconSize} clickHandler={this.props.clickHandler} /> : <Play iconSize={iconSize} clickHandler={this.props.clickHandler} />;
        return (
            <div className="Controls">
                <MaterialIcon onClick={this.props.previousClickHandler} icon="skip_previous" size={iconSize} color={"#fff"} />
                {playIcon}
                <MaterialIcon onClick={this.props.nextClickHandler} icon="skip_next" size={iconSize} color={"#fff"} />
            </div>
        )
    }
}

export default Controls;