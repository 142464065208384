import React from 'react';
import './404.css';
import '../Admin/Admin.css';

class NotFound extends React.Component {

    render(){
        return(
            <div className="NotFound Page">
                
                <div className="header">
                    <div className="Logo"><a href="/"><h1 className="AdminLogo">Tom Cole</h1></a></div>
                </div>
                <div className="main">
                    <h2>404</h2>
                </div>
                
                <div className="text">This page cant be found!</div>
            
            </div>
        )
    }
}

export default NotFound;