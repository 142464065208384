import React, { Component } from 'react';
import { ColorRing } from 'react-loader-spinner'
import Header from '../../Components/Header/Header';
import Showreel from '../../Assets/Video/tom_cole_&_henry_bristow_showreel.mp4';
import Img from '../../Assets/Images/tom&henry.jpg';
import Background from '../../Assets/Images/showreel_background.jpg';
import './TomHenry.css';
import Drawer from '../../Components/Drawer/Drawer';

class TomHenry extends Component {

    state = {
        showControls: false,
        loading: true
    }

    componentDidMount() {
        window.scrollTo(0,0);
        const context = this;
        const video = document.getElementById('showreel-player');
        video.volume = 0.7;
        // video.addEventListener('loadeddata', () => {
        //     alert('loadeddata')
        //     context.showControls();
        // }, false);
        video.addEventListener('loadedmetadata', () => {
            context.showControls();
        })
        video.addEventListener("ended", () => {
            const vidControls = document.getElementById('showreel-background');
            vidControls.classList.remove('out');
            vidControls.classList.add('in');
            video.classList.remove('in');
            video.classList.add('out');
            context.showControls();
        });
    }

    showControls = () => {
        this.setState({ showControls: true, loading:false })
    }

    navigateHandler = id => {
        this.props.history.push(`/#${id}`)
    }

    playHandler = () => {
        const video = document.getElementById('showreel-player');
        video.currentTime = '0';
        video.play();
        this.setState({ showControls: false })
        const vidControls = document.getElementById('showreel-background');
        vidControls.classList.remove('in');
        vidControls.classList.add('out');
        video.classList.remove('out');
        video.classList.add('in');
    }

    render() {
        return (
            <div className="App" style={{ backgroundColor: '#100e0e' }}>
                <Header light={true} delegateNav={true} navigateHandler={this.navigateHandler} />
                <Drawer delegateNav={true} navigateHandler={this.navigateHandler} />
                <div id='tomcolehenrybristow' className="Page">
                    <div id='vid-wrapper'>
                        <video id='showreel-player' preload="data">
                            <source src={Showreel} />
                        </video>
                        <div id='showreel-background'></div>
                        <div id="video-controls">
                            <h3>Tom Cole &amp; Henry Bristow</h3>
                            <h4>Showreel</h4>
                            
                            {this.state.showControls ?  <button id='watch-now' onClick={this.playHandler}>Watch Now</button> : null}
                            {this.state.loading ?
                                <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                    colors={['#e15b64', '#f47e60', '#fa7f00', '#ffc685', '#ff9600']}
                                />
                            :null}
                            <a id='watch' target='_blank' href='https://www.youtube.com/watch?v=fq6P3Hearx8&ab_channel=TomColeMusic'>Watch on YouTube</a>
                        </div>
                    </div>

                    <div id='content-wrapper'>
                        <div id='content-container'>
                            <p>Tom Cole & Henry Bristow have been long term friends and musical collaborators - Henry recorded, produced and played on Tom's EP <a target='_blank' href="https://open.spotify.com/album/0PnwR6Deo24mjND8GR0iVH">The Road Less Travelled</a> and is doing the same for his upcoming covers album - Stops on the Way to Here, so it seemed the natural progression to bring their complimentary musical styles together for a dedicated live musical vehicle, and thus Tom Cole & Henry Bristow was born. Combining Tom's finesse for all things folk and americana with Henry's virtuoso violin playing, and the opportunity to incorporate traditional jigs and reels both from America and the British Isles (and further afield as well!)</p>
                            <p>The pair have worked together on an ad hoc basis for many years, mainly in their local area of Hastings and East Sussex. 2022 marked the formation proper of Tom & Henry as a duo, with a summer tour of East Kent incorporating Broadstairs Folk Week a highlight, as well as playing at Hastings Fat Tuesday festival.</p>
                            <p>2023 is shaping up to be another progressive year for the duo, with the release of Tom's new album, more festival appearances, and a rumoured trip to more distant shores, complimenting their busy schedule on the local scene.</p>
                            <p style={{ textAlign: 'center' }}>For bookings or general enquiries, contact Tom at tom@tomcolemusic.co.uk </p>
                            <p style={{ textAlign: 'center' }}>Listen to The Road Less Travelled <a target='_blank' href="https://open.spotify.com/album/0PnwR6Deo24mjND8GR0iVH">here</a><br />(available on all streaming services)</p>
                            <p style={{ textAlign: 'center', marginBottom:0 }}>Both Tom and Henry's original work can be purchased from their respective Bandcamp pages:<br />
                            <a target='_blank' href='https://tomcole.bandcamp.com/'>Tom Cole</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a target='_blank' href='https://henryisaacbristow.bandcamp.com/'>Henry Bristow</a></p>
                           
                            <div style={{ width: "100%", textAlign: 'center' }}>
                                <img id='content-image' src={Img} alt="Tom & Henry" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default TomHenry;
