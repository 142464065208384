import React from 'react';
import './SongInfo.css';

const SongInfo = (props) => {
    return(
        <div className="SongInfo">
            <h3>{props.track.title}</h3>
            <h4>{props.track.album}</h4>
        </div>
    )
}

export default SongInfo;