import React from 'react';
import MaterialIcon from 'material-icons-react';
import Navigation from '../Navigation/Navigation';
import './Drawer.css';

class Drawer extends React.Component {

    state = {
        active: false
    }
    
    toggleDrawer = () => {
        this.setState({active: !this.state.active});
    }

    render() {
        const drawerClass = this.state.active ? "Drawer Active" : "Drawer"; 
        return (
            <div className="DrawerContainer">
                <div className="DrawerIcon">
                    <MaterialIcon onClick={this.toggleDrawer} icon="menu" size={36} color={"#fff"} />
                </div>
                <div className={drawerClass}>
                    <Navigation delegateNav={this.props.delegateNav} navigateHandler={(id) => this.props.navigateHandler(id)} toggleDrawer={this.toggleDrawer} />
                </div>
            </div>
        )
    }
}

export default Drawer;