import React from 'react';
import ContactForm from '../../Components/ContactForm/ContactForm';
import './Contact.css';

const Contact = () => {
    return (
        <div className="Contact Page" id="contact">
            
                <ContactForm />
            </div>
      
    )
}

export default Contact;