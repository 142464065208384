import React from 'react';
import './AlbumArt.css';

const AlbumArt = (props) => {

    return(
        <div className="AlbumArt">
           <img src={props.image} alt="Ramblin Man" />
        </div>
    )

}

export default AlbumArt;