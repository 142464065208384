import React from 'react';
import '../About.css';

const AboutPhotos = (props) => {
    return (
        <div className="About Section Column">
                <div className="Photo Large">
                    {props.children[0]}
                </div>
                <div className="Photos Small">
                    {props.children[1]}
                    {props.children[2]}
                </div>
        </div>
    )
}

export default AboutPhotos;