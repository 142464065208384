import React from 'react';
import './Tagline.css';

const Tagline = (props) => {
    return(
        <div className="Tagline">
            <h2>{props.tagline}</h2>
        </div>
    )
}

export default Tagline;