import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import TourEvent from './TourEvent/TourEvent';
import './Events.css';
import 'pure-react-carousel/dist/react-carousel.es.css';


class Events extends React.Component {

    state = {
        eventComponents: [],
        renderedComponents: 0,
        renderStarted: false
    }

    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onWindowScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onWindowScroll);
    }

    onWindowScroll = () => { // assuming you're using https://babeljs.io/docs/plugins/transform-class-properties/
        if (this.checkVisible(this.containerRef.current)) {
            this.timer = setInterval(this._renderEvents, 300);
            window.removeEventListener('scroll', this.onWindowScroll);
        };
    }

    checkVisible = (elm) => {
        var rect = elm.getBoundingClientRect();
        var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    }


    _renderEvents = () => {
        if (this.state.renderedComponents < this.props.events.length) {
            const events = [...this.state.eventComponents];
            events.push(<Slide index={this.props.events[this.state.eventComponents.length].key}><TourEvent key={this.props.events[this.state.eventComponents.length].key} event={this.props.events[this.state.eventComponents.length]} /></Slide>)
            this.setState(
                { eventComponents: events, renderedComponents: events.length, renderStarted: true }
            );

        } else {
            //window.removeEventListener('scroll', this.onWindowScroll);
            clearInterval(this.timer);
        }
        // const events = [...this.state.events];
        // const eventsRender = events.map(event => {
        //     return <TourEvent event={event} />
        // });
        // return eventsRender;
    }

    _renderSlider = () => {
        const width = document.body.offsetWidth;
        let slideWidth = 320;
        let visibleSlides = 4;
        if (width <= 720) {
            slideWidth = width - 16;
            visibleSlides = 1;
        }
        return (
            <div className="EventsDates">
                <CarouselProvider naturalSlideWidth={slideWidth} naturalSlideHeight={125} totalSlides={this.props.events.length} visibleSlides={visibleSlides}>
                    <Slider>
                        {this.state.eventComponents}
                    </Slider>
                    <ButtonBack>Back</ButtonBack>
                    <ButtonNext>Next</ButtonNext>
                </CarouselProvider>
            </div>
        )
    }

    render() {
       
        const eventContent = this.props.events.length > 0 ? this._renderSlider() : <div className="EventsDates"><p className="NoEvents">No events to show...</p></div>
        return (
            <div ref={this.containerRef} className="EventsWrapper">
                <div className="Container" style={{ minHeight: '511px' }}>
                    <h3>Events</h3>
                    {/* <h5>All upcoming gigs</h5> */}
                    {eventContent}

                </div>
            </div>
        )
    }

}

export default Events;